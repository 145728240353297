import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { DialogContentText, Typography } from "@mui/material";
import styles from "./subscriptions.module.css";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { TablePagination } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import isDesktop from "../isDesktop";
import SubscriptionsMobile from "./SubscriptionsMobile";

const Subscriptions = () => {
  const isDesktopDevice = isDesktop();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSpecDialog, setOpenSpecDialog] = useState(false);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSpecs, setSelectedSpecs] = useState([]);
  const [changedSkills, setChangedSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [filteredSpecs, setFilteredSpecs] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [actionType, setActionType] = useState("skills");
  const [searchQuery, setSearchQuery] = useState("");
  const [token,setToken] = useLocalStorage('token',null);
  const [skillsCompletion, setSkillsCompletion] = useState([]);
  const userId = localStorage.getItem("userId");
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updateDialog, setUpdateDialog] = useState(false);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedUsers = filteredUsers.slice(startIndex, endIndex);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleUpdate = (userId) => {
    const selected = users.find((user) => user.user_id === userId);
    console.log(selected);
    
    setSelectedUser(selected);
    setFormValues({
      id: selected.user_id,
      name: selected.name,
      email: selected.email,
      mobile: selected.mobile,
    });
    setUpdateDialog(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleUpdateUser = async (id) => {
    const { name, email, mobile } = formValues;
    console.log(formValues);

    if (!name || !email || !mobile) {
      console.error("All fields are required");
      return;
    }

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/updateUser/update/${id}`,
        {
          name,
          email,
          mobile,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("User data updated successfully");
        fetchUsers();
        setUpdateDialog(false);
      } else {
        toast.error("Error updating user data: ", response.data.message);
      }
    } catch (error) {
      toast.error("Error updating user data: ", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all?order=desc`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const reversedUsers = response.data.data.reverse(); // Reverse the array of users
        setUsers(reversedUsers);
        setFilteredUsers(reversedUsers); // Initially set filtered users to all users
      } else {
        console.error("Error fetching users: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  const fetchCompletionData = async (userId, token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/${userId}/skillCompletion`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return the completion data
    } catch (error) {
      console.error("Error fetching skills completion data: ", error);
      return null; // Return null in case of error
    }
  };

  useEffect(() => {
    if(isDesktopDevice) {
      fetchUsers();
      fetchCompletionData(token);
      fetchSkills();
    }
  }, [token]);

  // Function to handle opening the dialog and fetching completion data
  const handleOpenDialog = async (userId, action) => {
    
    
    try {
      // await fetchSkills(); // Fetch skills first

      if (action==="skills") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/subscription/skill-data/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data);
        const fetchedSkills=response.data.data;
        // const userSkills = response.data.user.skills || [];
        const pNull=fetchedSkills.filter(val=>val.is_active===null).map(val=>val.skill_id)
        // console.log(pNull);
        const sSkills=fetchedSkills.map(val=>val.is_active===null?false:val.is_active===0?false:true)
        console.log(sSkills);
        const selected = users.find((user) => user.user_id === userId);

        setSelectedUser(selected);
        setSelectedSkills(sSkills)
        setFilteredSkills(fetchedSkills);
        const completionData = await fetchCompletionData(userId, token);

      console.log(completionData !== null || completionData.length !== 0);
      if (completionData !== null) {
        // If completion data is fetched successfully
        setSkillsCompletion(completionData); // Update the state with completion data
     
        setOpenDialog(true);
      } else {
        // If completion data fetch fails, display an error message or handle gracefully
        throw new Error("Failed to fetch skills completion data.");
      }
      }else
      if (action==="specs") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/subscription/spec-data/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data);
        const fetchedSpecs=response.data.data;
        // const userSkills = response.data.user.skills || [];
        // console.log(pNull);
        const sSpecs=fetchedSpecs.map(val=>val.is_active===null?false:val.is_active===0?false:true)
        console.log(sSpecs);
        setSelectedSpecs(sSpecs)
        setFilteredSpecs(fetchedSpecs);
        setOpenSpecDialog(true)
      }
      setSelectedUserId(userId);
      // setSelectedSkills(userSkills);
      setActionType(action);

      // const completionData = await fetchCompletionData(userId, token); // Fetch completion data
      // console.log(completionData);
    } catch (error) {
      console.error(
        "Error opening dialog and fetching completion data: ",
        error
      );
      toast.error(
        "An error occurred while opening the dialog.",
        error.toString()
      );
    }
  };
  console.log(changedSkills);
  const handleUnsubscription=()=>{
  
      if(actionType === "update") {
        updateSkillStatus(changedSkills,0);
      }
    
  }

  // useEffect(()=>{
  //   setOpenDialog(true)
  // },[filteredSkills])

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenSpecDialog(false)
    setSelectedUserId(null);
    setSelectedSkills([]);
    setFilteredSkills([]);
    setFilteredSpecs([])
    setSelectedSpecs([]);
    setChangedSkills([]);
  };

  const handleButtonClick = (userId, action) => {
    handleOpenDialog(userId, action);
  };

  const handleResetButtonClick = (userId) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/change-password/${userId}/resetLimit`;

    axios
      .put(apiUrl)
      .then((response) => {
        console.log(response.data.message);
        toast.success("User limit has been reset successfully.");
      })
      .catch((error) => {
        console.error("Error occurred: ", error);
        toast.error(
          error.response?.data?.message ||
            "An error occurred. Please try again later."
        );
      });
  };

  const handleSkillToggle = (e,skillId,ind) => {
    console.log(e.target.checked);
    console.log(selectedSkills);
    
    setSelectedSkills((prevSkills) => {
      const newSkills=[...prevSkills]
       newSkills[ind]=!newSkills[ind]

      return newSkills;
    });

  };

  const handleSpecToggle = (e,skillId,ind) => {
    console.log(e.target.checked);
    console.log(selectedSkills);
    
    setSelectedSpecs((prevSpecs) => {
      const newSpecs=[...prevSpecs]
      newSpecs[ind]=!newSpecs[ind]

      return newSpecs;
    });

  };

  const updateSkillStatus = (skillId, isActive) => {
    if (skillId.length===0) {
      toast.error("No skill selected")
      return
    }
    const data = {
      user_id: selectedUserId,
      skill_id: skillId,
      is_active: isActive,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/enrollment/updateStatus`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("User Unsubscribed sucessfully")
        setOpenDialog(false)
        console.log("Status update success:", response.data);
      })
      .catch((error) => {
        toast.error("Update Failed.");
        console.error("Status update error:", error);
      });
  };

  const handleSubscription = async() => {
    setOpenDialog(false);
    const skillsToInsert=filteredSkills.filter((skill,index)=>{
      let active=false;
      if (skill.is_active===null && selectedSkills[index]===true) {
        return true;
      }
      return false

    }).map(val=>{
      return({
        skillId:val.skill_id,
        isActive:true
      })
    })
    
    const skillsToUpdate=filteredSkills.map((skill,index)=>{
      if (skill.is_active!==null && skill.is_active!=selectedSkills[index]) {
        return({
          skillId:skill.skill_id,
          isActive:selectedSkills[index]
        })
      }
    }).filter(val=>val!==undefined);

    // console.log(skillsToInsert);
    // console.log("Break");
    // console.log(skillsToUpdate);
    
    if (actionType=="skills") {
      try {
        const response=await axios.put( `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/subscription/skill-subscription`,{
          skillsToInsert:skillsToInsert||[],
          skillsToUpdate:skillsToUpdate||[],
          userId:selectedUserId
        })

        if (response.status===200) {
          toast.success("Skills Updated Sucessfully")
        }
      } catch (error) {
        toast.error("error Updating skills Status");
        console.error(error);
        
      }
    }
  };

  const handleSpecSubscription = async() => {
    setOpenSpecDialog(false);
    const specToInsert=filteredSpecs.filter((spec,index)=>{
      let active=false;
      if (spec.is_active===null && selectedSpecs[index]===true) {
        return true;
      }
      return false

    }).map(val=>{
      return({
        specId:val.specialization_id,
        isActive:true
      })
    })
    
    const specsToUpdate=filteredSpecs.map((spec,index)=>{
      if (spec.is_active!==null && spec.is_active!=selectedSpecs[index]) {
        return({
          specId:spec.specialization_id,
          isActive:selectedSpecs[index]
        })
      }
    }).filter(val=>val!==undefined);

    const finalArray=specToInsert.concat(specsToUpdate)
    
    if (actionType=="specs") {
      try {
        const response=await axios.put( `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/subscription/specialization-subscription`,{
          specArray:finalArray,
          userId:selectedUserId
        })

        if (response.status===200) {
          toast.success("Specialization Updated Sucessfully")
        }
      } catch (error) {
        toast.error("error Updating Specialization Status");
        console.error(error);
      }
    }
  };

  // Function to filter users based on search query
  const filterUsers = (query) => {
    if (!query) {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(
        (user) =>
          user.name.toLowerCase().includes(query.toLowerCase()) ||
          user.username.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  };

  useEffect(() => {
    isDesktopDevice && filterUsers(searchQuery);
  }, [searchQuery]);

  if (!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2 style={{marginTop: '5%'}}>You are unauthorized to access this page</h2>
      </div>
    );
  }

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
    const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
    return `${formattedDate} ${formattedTime}`;
  }
  
  return (
    <>
    {
      isDesktopDevice ? (
        <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        <Card elevation={3} className="col-md-12 p-3">
          <CardContent>
            <div className={styles.header}>
              <h2 style={{ marginRight: "1rem" }} className="page-header">
                Subscriptions
              </h2>
              <div className={styles.headerRight}>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={filteredUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TextField
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      height: '2.564vw',
                      boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'

                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <ToastContainer />
            <table className={styles.mainTable}>
              <thead className={styles.tableHead}>
                <tr>
                  <th style={{textAlign:'center', width: '6.538vw'}}>S. No.</th>
                  <th style={{width:'18.718vw', textAlign: 'left'}}>Name & Username</th>
                  <th style={{width:'20.513vw', textAlign: 'left'}}>Email</th>
                  <th style={{textAlign:'center', width: '15.641vw'}}>Mobile</th>
                  <th style={{textAlign:'center'}}>Date & Time</th>
                  <th style={{textAlign:'left', paddingLeft:'5.128vw'}}>Actions</th>
                </tr>
              </thead>
              <tbody >
                {displayedUsers.map((user) => (
                  <tr key={user.user_id} className={styles.tableBody}>
                    <td>{user.user_id}</td>
                    <td style={{textAlign:'left', fontSize: '1.026vw'}}>{user.name} <br/> <div style={{fontSize:'0.897vw'}}> {user.username} </div></td>
                    <td style={{textAlign:'left', fontSize: '1.026vw'}}>{user.email}</td>
                    <td style={{fontSize: '1.026vw'}}>{user.mobile}</td>
                    <td style={{width: '8vw', fontSize: '1.026vw'}}>{formatDate(user.created_at)}</td>
                    <td style={{paddingLeft:'5.128vw'}}>
                      <div className={styles.buttonContainer}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleButtonClick(user.user_id, "specs")
                          }
                          style={{
                            height: "2.051vw",
                            color: 'black',
                            backgroundColor: "#FFD6D2",
                            textTransform: "capitalize",
                            padding: "0.449vw 0.897vw",
                          }}
                          sx={{
                              fontSize: '0.897vw'
                          }}
                        >
                          Specialization
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() =>
                            handleButtonClick(user.user_id, "skills")
                          }
                          style={{
                            backgroundColor: "#D1F4DE",
                            height: "2.051vw",
                            color: 'black',
                            textTransform: "capitalize",
                            padding: "0.385vw 0.641vw"
                          }}
                          sx={{
                            fontSize: '0.897vw'
                        }}
                        >
                          Skills
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          fullWidth
                          onClick={() => handleResetButtonClick(user.user_id)}
                          style={{
                            height: "2.051vw",
                            color: 'black',
                            backgroundColor: "#FCF6BC",
                            textTransform: "capitalize",
                            padding: "0.385vw 0.641vw"
                          }}
                          sx={{
                            fontSize: '0.897vw',
                        }}
                        >
                          Reset Limits
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            handleUpdate(user.user_id);
                          }}
                          style={{
                            backgroundColor: "#CBEBF6",
                            height: "2.051vw",
                            color: 'black',
                            textTransform: "capitalize",
                            padding: "0.385vw 0.641vw"
                          }}
                          sx={{
                            fontSize: '0.897vw'
                        }}
                        >
                          Update
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </tbody>
              <Dialog open={openDialog} onClose={handleCloseDialog}
              PaperProps={{
                style: {
                  height:'auto',
                  padding: '1.5rem',
                  borderRadius: '0.513vw',
                  boxShadow: '4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80'
                }
              }}
              >
                <DialogTitle style={{paddingLeft: '0', fontWeight: '600', paddingTop: '0'}}>Student Skill Management</DialogTitle>
                <div className="dialog-content">
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1.282vw'}}>
                    <div style={{fontSize: '0.897vw', fontWeight: '500'}}>
                      Name: {selectedUser.name}
                    </div>
                    <div style={{fontSize: '0.897vw', fontWeight: '500'}}>
                      Username: {selectedUser.username}
                    </div>
                  </div>
                  <table className="table">
                    <thead className={styles.dialogTableHead}>
                      <tr>
                        <th style={{textAlign: 'left'}}>Skill Name</th>
                        <th>Completion %</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredSkills.map((skill,index) => (
                        <tr key={skill.skill_id}>
                          <td style={{textAlign: 'left'}}>{skill.skill_name}</td>
                          <td>
                            <Typography style={{ fontSize: "0.8125rem" }}>
                              {Math.floor(
                                skillsCompletion.find(
                                  (completion) =>
                                    completion.skill_id === skill.skill_id
                                )?.completion_percentage || 0
                              )}
                              %
                            </Typography>
                          </td>
                          <td>
                            <Switch
                              value={skill.skill_id}
                              onChange={(e) => handleSkillToggle(e,skill.skill_id,index)}
                              checked={selectedSkills[index]}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {actionType === "skills" && (
                  <div className={styles.buttonContainer}>
                    <Button
                      variant="outlined"
                      onClick={handleCloseDialog}
                      style={{
                        height: '2.564vw',
                        width: '15.385vw',
                        backgroundColor: 'transparent',
                        color: 'black',
                        textTransform: 'capitalize',
                        border: '2px solid #313C63',
                        flex: '1',
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubscription}
                      style={{
                        height: '2.564vw',
                        width: '15.385vw',
                        backgroundColor: '#37446F',
                        textTransform: 'capitalize',
                        flex: '1'
                      }}
                    >
                      Update Skills
                    </Button>
                  </div>
                )}
                {actionType === "update" && (
                  <div style=
                    {{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCloseDialog}
                      style={{
                        height: '2.564vw',
                        width: '15.385vw',
                        borderRadius: '4px',
                        textTransform: 'capitalize',
                        border: '2px solid #313C63',
                        color: '#000000'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUnsubscription}
                      style=
                      {{
                        height: '2.564vw',
                        width: '15.385vw',
                        borderRadius: '4px',
                        textTransform: 'capitalize',
                        color: '#ffffff',
                        backgroundColor: '#37446F'
                      }}
                    >
                      Unsubscribe
                    </Button>
                  </div>
                )}
              </Dialog>
              <Dialog
                open={openSpecDialog}
                onClose={handleCloseDialog}
                PaperProps={{
                  style: {
                    width: '35.128vw',
                    padding: '1.5rem',
                    borderRadius: '0.513vw',
                    boxShadow: '4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80'
                  }
                }}
              >
                <DialogTitle style={{paddingLeft: '0', fontWeight: '600', paddingTop: '0'}}>Add Specialization</DialogTitle>
                <div className="dialog-content">
                  <table className="table">
                    <thead className={styles.dialogTableHead}>
                      <tr>
                        <th style={{textAlign: 'left'}}>Specialization Name</th>
                        {/* <th>Completion %</th> */}
                        <th>Toggle</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredSpecs.map((spec,index) => (
                        <tr key={spec.specialization_id}>
                          <td style={{textAlign: 'left'}}>{spec.specialization_name}</td>
                          <td>
                            <Switch
                              value={spec.specialization_id}
                              onChange={(e) => handleSpecToggle(e,spec.specialization_id,index)}
                              checked={selectedSpecs[index]}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {actionType === "specs" && (
                  <div className={styles.buttonContainer}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCloseDialog}
                      style={{
                        height: '2.564vw',
                        width: '15.385vw',
                        borderRadius: '4px',
                        textTransform: 'capitalize',
                        border: '2px solid #313C63',
                        color: '#000000'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSpecSubscription}
                      style={{
                        height: '2.564vw',
                        backgroundColor: '#37446F',
                        textTransform: 'capitalize',
                        width: '-webkit-fill-available'
                      }}
                    >
                      Update Specialization
                    </Button>
                  </div>
                )}
              </Dialog>
              <Dialog open={updateDialog} onClose={handleUpdateDialogClose}>
                <DialogTitle style={{fontWeight: '700'}}>Update User Details</DialogTitle>
                  <form className="m-4" style={{display:'flex', flexDirection: 'column', gap: '0.5rem'}}>
                  <TextField
                    className={styles.customTextfield}
                    type="text"
                    label="Name"
                    name="name"
                    variant="outlined"
                    value={formValues.name}
                    onChange={handleFormChange}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                              borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                              borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                          },
                      },
                      mb: '1rem'
                  }}
                  />
                  <TextField
                    className={styles.customTextfield}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                              borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                              borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                          },
                      },
                      mb: '1rem'
                    }}
                    type="text"
                    label="Email"
                    name="email"
                    variant="outlined"
                    value={formValues.email}
                    onChange={handleFormChange}
                  />
                  <TextField
                    className={styles.customTextfield}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                              borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                              borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                          },
                      },
                      mb: '1rem'
                    }}
                    type="text"
                    label="mobile"
                    name="mobile"
                    variant="outlined"
                    value={formValues.mobile}
                    onChange={handleFormChange}
                    />
                    <div style={{display:'flex',alignItems:"center", gap:'0.5rem'}}>
                    <Button
                      onClick={handleUpdateDialogClose}
                      className="outlined-button"
                      style={{flex: '1'}}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{flex: '1'}}
                      className="filled-button"
                      onClick={() => {
                        handleUpdateUser(formValues.id);
                      }}
                    >
                      Update
                    </Button>
                    </div>
                  </form>
              </Dialog>
            </table>
          </CardContent>
        </Card>
        
      </div>
    </div>
      ) : (
        <SubscriptionsMobile />
      )
    }
    </>
  );
};

export default Subscriptions;
