import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axios from "axios";
import Battle from "../battle/Battle";
import Question from "../question/Question";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Grid,
  DialogContentText,
} from "@mui/material";
import service from "../stage/stageService";
import Switch from "@mui/material/Switch";
import { ArrowBackIosNewRounded } from "@mui/icons-material";

const Mission = () => {
  const { skillId, stageId } = useParams();
  const [missions, setMissions] = useState([]);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [isReplicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [currentMissionId, setCurrentMissionId] = useState(null);
  const [replicateFormData, setReplicateFormData] = useState({
    newSkillId: "",
    newStageId: "",
    newMissionName: "",
  });
  const [formData, setFormData] = useState({ missionId: "", name: "" });
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(4);
  const [insertedQuestionIds, setInsertedQuestionIds] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [missionToDelete, setMissionToDelete] = useState(null);
  const [skills, setSkills] = useState([]);
  const [stages, setStages] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);

  const [token, setToken] = useLocalStorage("token", null);
  const [visibility, setVisibility] = useState(formData.visibility === "1");

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/skills/${skillId}/stage`);
  };

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.checked);
    setFormData((prevData) => ({
      ...prevData,
      visibility: event.target.checked ? "1" : "0",
    }));
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSkills(response.data.data || []);
    } catch (error) {
      console.error("Error fetching skills:", error);
      setSkills([]);
    }
  };

  const fetchStages = async (skillId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/stages/all/${skillId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedStages = response.data.data.stages;
      console.log("Fetched Stages:", fetchedStages);
      setStages(Array.isArray(fetchedStages) ? fetchedStages : []);
    } catch (error) {
      console.error("Error fetching stages:", error);
      setStages([]);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    if (selectedSkill) {
      console.log("Selected Skill ID:", selectedSkill.skill_id);
      fetchStages(selectedSkill.skill_id);
    }
  }, [selectedSkill]);

  const inputFields = [
    { label: "Mission ID", name: "missionId", type: "number", disabled: true },
    { label: "Mission Name", name: "name" },
    { label: "Video Link", name: "videoLink" },
    { label: "Mission Order", name: "missionOrder", type: "number" },
    // { label: "Question Type", name: "questionType" },
    { label: "Instructions", name: "instructions", type: "multiline" },
    { label: "Display Columns", name: "displayColumns", type: "number" },
    { label: "Speed", name: "speed", type: "time" },
    { label: "Accuracy %", name: "accuracy", type: "number" },
    { label: "Max Questions", name: "maxQuestions", type: "number" },
    // { label: "Visibility", name: "visibility", type: "number" },
    { label: "Coin Type", name: "coinType", type: "number" },
    { label: "Specialization ID", name: "specializationId", type: "number" },
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/all/${stageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.data) {
        setMissions(response.data.data);
      } else {
        console.error("Empty mission data received");
      }
    } catch (error) {
      console.error("Error fetching mission data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleAdd = () => {
    setFormData({ missionId: "", name: "" });
    setAddDialogOpen(true);
  };

  const handleEdit = (missionId) => {
    const missionToEdit = missions.find(
      (mission) => mission.mission_id === missionId
    );

    setFormData({
      missionId: missionToEdit.mission_id,
      name: missionToEdit.mission_name,
      videoLink: missionToEdit.video_link,
      missionOrder: missionToEdit.mission_order,
      questionType: missionToEdit.question_type || "lorem",
      instructions: missionToEdit.instructions,
      displayColumns: missionToEdit.display_columns,
      speed: missionToEdit.speed,
      accuracy: missionToEdit.accuracy,
      maxQuestions: missionToEdit.max_questions,
      visibility: missionToEdit.visibility,
      coinType: missionToEdit.coin_type,
      specializationId: missionToEdit.specialization_id,
    });

    setAddDialogOpen(true);
  };

  const handleReplicate = (missionId) => {
    setCurrentMissionId(missionId);
    setReplicateDialogOpen(true);
    setReplicateFormData({
      newSkillId: "",
      newStageId: "",
      newMissionName: "",
    });
    setSelectedSkill(null);
    setSelectedStage(null);
    setStages([]);
  };

  const handleReplicateDialogClose = () => {
    setReplicateDialogOpen(false);
    setCurrentMissionId("");
    setReplicateFormData({
      newSkillId: "",
      newStageId: "",
      newMissionName: "",
    });
    setSelectedSkill(null);
    setSelectedStage(null);
    setStages([]);
  };

  const handleSkillSelectChange = (event) => {
    const selectedSkillId = event.target.value;
    const selectedSkillObj = skills.find(
      (skill) => skill.skill_id === selectedSkillId
    );
    setSelectedSkill(selectedSkillObj);
    setReplicateFormData((prevData) => ({
      ...prevData,
      newSkillId: selectedSkillId,
      newStageId: "",
    }));
    setSelectedStage(null);
    fetchStages(selectedSkillId);
  };

  const handleStageSelectChange = (event) => {
    const selectedStageId = event.target.value;
    const selectedStageObj = stages.find(
      (stage) => stage.stage_id === selectedStageId
    );
    setSelectedStage(selectedStageObj);
    setReplicateFormData((prevData) => ({
      ...prevData,
      newStageId: selectedStageId,
    }));
  };

  const handleReplicateSubmit = async () => {
    try {
      if (
        !replicateFormData.newSkillId ||
        !replicateFormData.newStageId ||
        !replicateFormData.newMissionName
      ) {
        toast.error("Please fill in all fields.");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/replicate/mission/${currentMissionId}`,
        {
          newSkillId: replicateFormData.newSkillId,
          oldStageId: stageId,
          newStageId: replicateFormData.newStageId,
          newMissionName: replicateFormData.newMissionName,
          oldMissionId: currentMissionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        fetchData();
        setReplicateDialogOpen(false);
      } else if (response.status === 400) {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error("An error occurred during replication.");
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/delete/${missionToDelete}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.delete(apiUrl, config);

      if (response.status === 200) {
        console.log(`Mission ${missionToDelete} deleted successfully`);
        const updatedMissions = missions.filter(
          (mission) => mission.mission_id !== missionToDelete
        );
        setMissions(updatedMissions);
        toast.success("Missions Deleted Sucessfully")
      } else {
        console.error(`Error deleting Mission ${missionToDelete}`, response.data);
        toast.error("Error deleting Mission ")
      }
    } catch (error) {
      console.error(`Error deleting Mission ${missionToDelete}`, error);
      toast.error("Error deleting Mission ")
    }
    setDeleteOpen(false)
    setMissionToDelete(null)
  };

  const handleDelete = async (missionId) => {
    setDeleteOpen(true)
    setMissionToDelete(missionId)
  };

  const handleDownload = async (token, missionId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/exercise/download/${missionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "questions.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Questions downloaded successfully");
    } catch (error) {
      console.error("Error downloading questions:", error);
      toast.error(`Error downloading questions: ${error.message}`);
    }
  };

  const onDownloadQuestions = (missionId) => {
    if (missionId && token) {
      handleDownload(token, missionId);
    } else {
      toast.error("No mission ID or token available");
    }
  };

  const handleCloseDialog = () => {
    setInsertedQuestionIds([]);
    setAddDialogOpen(false);
    setReplicateDialogOpen(false);
    setCurrentMissionId(null);
  };

  const handleSave = async (skillId, stageId) => {
    try {
      if (!isTimeValid(formData.speed)) {
        toast.error('Invalid time format for Speed. Please use "hh:mm:ss".');
        return;
      }
      if (formData.missionId) {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/update/${formData.missionId}`,
          {
            missionName: formData.name,
            videoLink: formData.videoLink,
            missionOrder: formData.missionOrder,
            questionType: formData.questionType || "lorem",
            instructions: formData.instructions,
            displayColumns: formData.displayColumns,
            speed: formData.speed,
            accuracy: formData.accuracy,
            maxQuestions: formData.maxQuestions,
            visibility: formData.visibility,
            questionIds: insertedQuestionIds,
            coinType: formData.coinType,
            specializationId: formData.specializationId,
            skillId: skillId,
            stageId: stageId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success(`${response.data.message}`);
          const updatedMissions = missions.map((mission) =>
            mission.mission_id === formData.missionId
              ? { ...mission, ...formData }
              : mission
          );
          setMissions(updatedMissions);
          fetchData();
        } else {
          toast.error(`${response.data.message}`);
        }
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/add`,
          {
            missionName: formData.name,
            videoLink: formData.videoLink,
            missionOrder: formData.missionOrder,
            questionType: formData.questionType || "Lorem",
            instructions: formData.instructions,
            displayColumns: formData.displayColumns,
            speed: formData.speed,
            accuracy: formData.accuracy,
            maxQuestions: formData.maxQuestions,
            questionIds: insertedQuestionIds,
            visibility: formData.visibility,
            coinType: formData.coinType,
            specializationId: formData.specializationId,
            skillId: skillId,
            stageId: stageId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success(`${response.data.message}`);
          fetchData();
        } else {
          toast.error(`${response.data.message}`);
        }
      }

      handleCloseDialog();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error("An error occurred while saving the mission.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isTimeValid = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(time);
  };

  const handleQuestionsAdded = (ids) => {
    setInsertedQuestionIds(ids);
  };

  function formatTime(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);

    if (hours === 0) {
      if (minutes === 0) {
        return `${seconds} sec`;
      } else if (seconds === 0) {
        return `${minutes} mins`;
      } else {
        return `${minutes} mins ${seconds} sec`;
      }
    } else if (hours === 1) {
      if (minutes === 0) {
        if (seconds === 0) {
          return `${hours} hr`;
        } else {
          return `${hours} hr ${seconds} sec`;
        }
      } else if (seconds === 0) {
        return `${hours} hr ${minutes} mins`;
      } else {
        return `${hours} hr ${minutes} mins ${seconds} sec`;
      }
    } else {
      if (minutes === 0) {
        if (seconds === 0) {
          return `${hours} hrs`;
        } else {
          return `${hours} hrs ${seconds} sec`;
        }
      } else if (seconds === 0) {
        return `${hours} hrs ${minutes} mins`;
      } else {
        return `${hours} hrs ${minutes} mins ${seconds} sec`;
      }
    }
  }

  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        {isAuthorized ? (
          <Card className="col-md-12 p-3 pt-5 shadow-none">
            <CardContent>
              <div className="top-div">
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "1vw",
                  }}
                >
                  <ArrowBackIosNewRounded
                    onClick={handleBack}
                    style={{ cursor: "pointer" }}
                  />
                  <h2>Missions</h2>
                </div>
                <ToastContainer />
                <Button
                  variant="contained"
                  onClick={handleAdd}
                  className="filled-button"
                >
                  Add
                </Button>
              </div>
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>S. No.</th>
                    <th className="text-left">Name</th>
                    <th>No. of Questions</th>
                    <th>Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {missions.map((mission) => (
                    <tr key={mission?.mission_id}>
                      <td>{mission?.mission_id || ""}</td>
                      <td className="text-left">
                        {mission?.mission_name || ""}
                      </td>
                      <td>{mission?.max_questions}</td>
                      <td>{formatTime(mission?.speed)}</td>
                      <td>
                        <Button
                          variant="contained"
                          onClick={() => handleEdit(mission.mission_id)}
                          className="g-but mr-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleReplicate(mission.mission_id)}
                          className="y-but mr-2"
                        >
                          Replicate
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleDelete(mission.mission_id)}
                          className="r-but"
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Dialog
                open={deleteOpen}
                onClose={(prev) => !prev}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ textAlign: "center" }}
              >
                <ErrorOutlineIcon
                  style={{
                    color: "red",
                    fontSize: "32px",
                    margin: "12px auto",
                  }}
                />
                <DialogTitle
                  id="alert-dialog-title"
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "0",
                  }}
                >
                  {"Are you Sure?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ fontSize: "12px" }}
                  >
                    You won't be able to revert this!
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDeleteOpen(false);
                      setMissionToDelete(null);
                    }}
                    style={{
                      textTransform: "none",
                      width: "120px",
                      height: "36px",
                      border: "2.03px solid #37446F",
                      borderRadius: "5.85px",
                      background: "none",
                      color: "black",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmDelete}
                    style={{
                      textTransform: "none",
                      width: "120px",
                      height: "36px",
                      borderRadius: "5.85px",
                      background: "#37446F",
                      color: "white",
                    }}
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </CardContent>
          </Card>
        ) : (
          <h2>You are unauthorized to access this page.</h2>
        )}

        {/* Replication Dialog */}
        <Dialog
          open={isReplicateDialogOpen}
          onClose={handleReplicateDialogClose}
          PaperProps={{
            style: {
              width: "35.128vw",
              borderRadius: "0.513vw",
              boxShadow:
                "4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80",
            },
          }}
        >
          <DialogTitle>Replicate Mission</DialogTitle>
          <DialogContent>
            <FormControl
              fullWidth
              margin="normal"
              className="customTextfield"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
            >
              <InputLabel id="skill-select-label">Skill</InputLabel>
              <Select
                labelId="skill-select-label"
                value={replicateFormData.newSkillId}
                onChange={handleSkillSelectChange}
                label="Skill"
              >
                {skills.map((skill) => (
                  <MenuItem key={skill.skill_id} value={skill.skill_id}>
                    {skill.skill_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              className="customTextfield"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
            >
              <InputLabel id="stage-select-label">Stage</InputLabel>
              <Select
                labelId="stage-select-label"
                value={replicateFormData.newStageId}
                onChange={handleStageSelectChange}
                label="Stage"
              >
                {stages.map((stage) => (
                  <MenuItem key={stage.stage_id} value={stage.stage_id}>
                    {stage.stage_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="New Mission Name"
              name="newMissionName"
              value={replicateFormData.newMissionName}
              onChange={(e) =>
                setReplicateFormData({
                  ...replicateFormData,
                  newMissionName: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              type="text"
              className="customTextfield"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
            />
            <DialogActions>
              <Button
                onClick={handleReplicateDialogClose}
                className="outlined-button"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleReplicateSubmit}
                className="filled-button"
                variant="contained"
              >
                Replicate
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {/* Add/Edit Mission Dialog */}
        <Dialog
          open={isAddDialogOpen}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              width: "68.718vw",
              maxWidth: "100vw",
              padding: "1.5rem",
              borderRadius: "0.513vw",
              boxShadow:
                "4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80",
            },
          }}
        >
          <DialogTitle style={{ padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{formData.missionId ? "Update Mission" : "Add Mission"}</div>
              <Button
                variant="contained"
                onClick={() => onDownloadQuestions(formData.missionId)}
                className="filled-button"
              >
                Download Questions
              </Button>
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <Grid container spacing={0}>
              {inputFields.map((field, index) => (
                <Grid item xs={6} key={field.name}>
                  <TextField
                    label={field.label}
                    name={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    type={`${
                      field.label.includes("Order") ? "Number" : "text"
                    }`}
                    disabled={field.disabled}
                    error={
                      field.name === "speed" && !isTimeValid(formData.speed)
                    }
                    placeholder={
                      field.name === "speed" ? "hh:mm:ss" : field.label
                    }
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Question
              onQuestionsAdded={handleQuestionsAdded}
              questionType={formData.questionType}
              missionId={formData.missionId}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={visibility}
                  onChange={handleVisibilityChange}
                  name="visibility"
                  color="primary"
                />
              }
              label="Visibility"
            />
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={handleCloseDialog}
                variant="outlined"
                className="outlined-button"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSave(skillId, stageId)}
                variant="contained"
                className="filled-button"
              >
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
      <Battle />
    </div>
  );
};

export default Mission;
