import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { ArrowBackIosNewRounded } from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styles from "./BlogCategories.module.css";
import axios from "axios";
import isDesktop from "../isDesktop";
import BlogCategoriesMobile from "./BlogCategoriesMobile";
import DeleteDialog from "../Components/ConfirmationDialog/DeleteDialog";

const BlogCategories = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCatId, setSelectedCatId] = useState("")
  const [categoryDetails, setCategoryDetails] = useState({
    name: "",
    order: "",
  });
  const [isOpen, setIsOpen] = useState({
    add: false,
    update: false,
  });
  const navigate = useNavigate();
  /* Delete Dialog Box */
  const [selectedCatForDelete, setSelectedCatForDelete] = useState("");
  const [openDelDialog, setOpenDelDialog] = useState(false);
  const handleDeleteDialog = () => {
    setOpenDelDialog(true);
  };
  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/categories/all`
      );

      if ((response.status = 200)) {
        console.log(response.data.data);
        setCategoriesData(response.data.data);
      }
    } catch (error) {
      console.log(error.response.data.error);

      toast.error(error.response.data.message);
    }
  };

  const onArticleClick = (id) => {
    navigate(`details/${id}`);
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const handleInputChange = (e) => {
    const name = e.target.name;
    setCategoryDetails((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleDialogClose = () => {
    setSelectedCatId("")
    setIsOpen({
      add: false,
      update: false,
    });
    setCategoryDetails({
      name: "",
      order: "",
    });
  };

  const onAddClick=()=>{
    setIsOpen(prev=>({
      ...prev,
      add:true
    }))
  }


  const onEditClick=async(id)=>{
    try {
      const response =await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${id}`)
      console.log(response.data.data);

      setCategoryDetails({
        name:response.data.data.category_name,
        order:response.data.data.category_order
      })
      setSelectedCatId(response.data.data.category_id)
      setIsOpen(prev=>({
        ...prev,
        update:true
      }))
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  const onCategoryAdd=async()=>{
    try {
     const response =await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category`,{
      name:categoryDetails.name,
      order:categoryDetails.order
      })

     if (response.status=200) {
        toast.success("Category Added Sucessfully");
        fetchAllCategories()
        handleDialogClose()
      }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error(error.response.data.error);

    }
  }

  const onCategoryUpdate=async()=>{
    try {
     const response =await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${selectedCatId}`,{
      name:categoryDetails.name,
      order:categoryDetails.order
      })

     if (response.status=200) {
        toast.success("Category Added Sucessfully");
        fetchAllCategories()
        handleDialogClose()
      }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error(error.response.data.error);

    }
  }

  const onCategoryDelete=async(id)=>{
    try {
      const response=await axios.delete(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${id}`)

      if (response.status===200) {
        toast.success("Category Deleted Sucessfully")
        fetchAllCategories()
        setOpenDelDialog(false)
      }

    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);

    }
  }
  const handleBack = () => {
    navigate('/dashboard');
  }
  const isDesktopDevice = isDesktop();
  return (
    <>
      {
        isDesktopDevice ? (
            <div style={{ paddingTop: '5.5vw' }}>
                  <ToastContainer />
              <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                <CardContent>
                  <div className={styles.header}>
                    <h2 className={styles.pageHeader}>
                      <ArrowBackIosNewRounded onClick={handleBack} style={{cursor: 'pointer'}}/> Blog Categories
                    </h2>
                    <div className={styles.headerRight}>
                      {/* <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={filteredUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                      <Button
                        style={{
                          width: "15.385vw",
                          height: "2.564vw",
                          padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                          gap: "0.641vw",
                          borderRadius: "4px",
                          color: "#ffffff",
                          fontSize: "0.897vw",
                          backgroundColor: "#37446F",
                          boxShadow:
                            " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                        }}
                  onClick={()=>{
                          onAddClick()
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                  <table className={styles.mainTable}>
                    <thead className={styles.tableHead}>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            paddingLeft: "2vw",
                            width: "20%",
                          }}
                        >
                          S. No.
                        </th>
                        <th style={{ width: "50%", textAlign: "left" }}>
                          Category Name
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            paddingLeft: "5.128vw",
                            width: "fit-content",
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoriesData.map((category) => (
                        <tr key={category?.category_id} className={styles.tableBody}>
                          <td
                            style={{
                              textAlign: "left",
                              paddingLeft: "3vw",
                              width: "20%",
                            }}
                          >
                            {category?.category_id}
                          </td>
                          <td style={{ textAlign: "left" }}>
                            {category?.category_name}
                          </td>
                          <td style={{ paddingLeft: "5.128vw" }}>
                            <div className={styles.buttonContainer}>
                              <Button
                                variant="contained"
                                fullWidth
                          onClick={() => {onEditClick(category?.category_id)}}
                                style={{
                                  backgroundColor: "#D1F4DE",
                                  height: "2.051vw",
                                  width: "7.179vw",
                                  color: "black",
                                  textTransform: "capitalize",
                                  fontSize: '0.875rem'
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                  onArticleClick(category?.category_id);
                                }}
                                style={{
                                  backgroundColor: "#CBEBF6",
                                  height: "2.051vw",
                                  width: "7.179vw",
                                  color: "black",
                                  textTransform: "capitalize",
                                  fontSize: '0.875rem'
                                }}
                              >
                                Article
                              </Button>
                              <Button
                                variant="contained"
                                fullWidth
                          onClick={() => {setSelectedCatForDelete(category?.category_id); handleDeleteDialog();}}
                                style={{
                                  backgroundColor: "#FFD6D2",
                                  height: "2.051vw",
                                  width: "7.179vw",
                                  color: "black",
                                  textTransform: "capitalize",
                                  fontSize: '0.875rem'
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardContent>
              </Card>
              <Dialog open={isOpen.add || isOpen.update} onClose={handleDialogClose}>
                <DialogTitle style={{fontWeight: '600'}}>
            {isOpen.add ? "Add New CateGory" : "Update Category"}
                </DialogTitle>
                <DialogContent>
                  <TextField
                    name="name"
                    type="text"
                    fullWidth
                    className="mt-3"
                    label="Category Name"
                    value={categoryDetails.name}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                      required: false
                    }}
                    InputProps={{
                      style: {
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      },
                    }}
                  />
                  <TextField
                    name="order"
                    type="text"
                    fullWidth
                    className="mt-3"
                    label="Category Order"
                    value={categoryDetails.order}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                      required: false
                    }}
                    InputProps={{
                      style: {
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      },
                    }}
                  />
                </DialogContent>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1.282vw', padding: '24px', paddingTop: '0'}}>
                  <Button
                    className="outlined-button"
                    style={{ flex: '1', width: 'auto !important'}}
              onClick={() => {handleDialogClose()}}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="filled-button"
                    style={{ flex: '1', width: 'auto !important'}}
              onClick={() => {isOpen.add?onCategoryAdd():onCategoryUpdate()}}
                  >
              {isOpen.add?"Add":"Update"}
                  </Button>
                </div>
              </Dialog>
              <DeleteDialog 
                open={openDelDialog}
                onClose={() => {setOpenDelDialog(false)}}
                onDelete={() => {onCategoryDelete(selectedCatForDelete)}}
                category={selectedCatForDelete}
              />
            </div>
        ) : (
          <BlogCategoriesMobile />
        )
      }
    </>
  );
};

export default BlogCategories;
