import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import smartsaLogo from '../assets/smartsa-new-logo-light.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as DefaultProfileIcon } from '../assets/default_profile_icon.svg'
import axios from 'axios';
import styles from "./Header.module.css"
import { useLocalStorage } from '@uidotdev/usehooks';

const Header = ({ onMenuClick, isLoggedIn }) => {

  const [empName, setEmpName] = useState('');
  const userId = localStorage.getItem('userId');
  const [token,setToken] = useLocalStorage("token",null)
  const navigate = useNavigate()

  const handleNavigation = (route) => {
    navigate('/profile');
  };

  // console.log(token);
  
  useEffect(() => {
    const fetchEmployeeName = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setEmpName(response.data.data.emp_name || '');
      } catch (error) {
        console.error('Failed to fetch employee name:', error);
      }
    };

    if (userId && token) {
      fetchEmployeeName();
    }
  }, [userId,token]);
  const isDesktop = window.innerWidth >= 768;
  return isDesktop && (
      <AppBar
        style=
        {{
          background: "#EAF1F7",
          borderRadius: '10px',
          width: '98.974vw',
          height: '4.359vw',
          margin: '0.513vw 0.513vw 0 0.513vw',
          boxShadow: '9.02px 9.02px 18px 0px #AEAEC066, -9.02px -9.02px 18px 0px #FFFFFF'

        }}>
        <Toolbar sx={{ borderRadius: '10px' }}>
          <div
            style=
            {{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {token && token!==null && <IconButton edge="start" aria-label="menu" onClick={onMenuClick}>
                <MenuIcon
                  style=
                  {{
                    height: '2.564vw',
                    width: '2.564vw',
                    padding: '6px',
                    borderRadius: '50%',
                    boxShadow: '6.21px 6.21px 12.41px 0px #AEAEC066, -4.14px -4.14px 12.41px 0px #FFFFFF'
                  }} />
              </IconButton>}
              <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  <img src={smartsaLogo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
                </Typography>
              </Link>
            </div>
            {token && token!==null &&  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={handleNavigation}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <DefaultProfileIcon />
              </IconButton>
              <div style={{ fontSize: '1.026vw', fontWeight: '600', color: 'black' }}>Hi, {empName}</div>
            </div>}
          </div>
        </Toolbar>
      </AppBar>
  );
};

export default Header;
