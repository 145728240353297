import React, { useEffect, useState } from "react";
import styles from "./Brands.module.css";
import isDesktop from "../isDesktop";
import { toast,ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";

const Brands = () => {
  const [brandsData, setBrandsData] = useState([]);
  const [brandName, setBrandName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [brandOrder, setBrandOrder] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isOpen, setIsOpen] = useState({
    add: false,
    update: false,
  });

  const isDesktopDevice = isDesktop();
  const navigate = useNavigate();

  const onAddClick = () => {
    setIsOpen((prev) => ({
      ...prev,
      add: true,
    }));
  };

  const fetchBrandsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand/all`
      );

      setBrandsData(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };

  console.log(brandsData, "brands data");
  

  useEffect(() => {
    fetchBrandsData();
  }, []);

  const onVoucherClick = (id) => {
    return navigate(`vouchers/${id}`);
  };

  const onEditClick = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand/${id}`
      );

      const data = response.data.data;
      setBrandName(data.brand_name);
      setBrandOrder(data.brand_order);
      setSelectedBrandId(id)
      setImageUrl(data.icon_url);
      setIsOpen((prev) => ({
        ...prev,
        update: true,
      }));
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };

  const onBrandUpdate = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand/${selectedBrandId}`,
        {
          brandOrder: brandOrder,
          brandName: brandName,
          iconUrl: imageUrl,
        }
      );
      if (response.status == 200) {
        toast.success("Data updated Sucessfully");
        handleDialogClose()
        fetchBrandsData()
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };

  const onBrandAdd = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand`,
        {
          brandOrder: brandOrder,
          brandName: brandName,
          iconUrl: imageUrl,
        }
      );

      if (response.status == 200) {
        toast.success("Data added sucessfully");
        handleDialogClose()
        fetchBrandsData()
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };

  const onBrandDelete = async (id) => {
    setSelectedBrandId(id);
    setDeleteOpen(true);
  };

  const handleDialogClose = () => {
    setBrandName("");
    setBrandOrder("");
    setImageUrl("");
    setIsOpen({
      add: false,
      update: false,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand/${selectedBrandId}`
      );
      if (response.status == 200) {
        toast.success("Data updated Sucessfully");
        setDeleteOpen(false)
        fetchBrandsData()
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };

  return (
    <>
    <ToastContainer />
      <div style={{ paddingTop: "5.5vw" }}>
        <Card
          elevation={3}
          className="col-md-12 p-3"
          style={{ boxShadow: "none" }}
        >
          <CardContent>
            <div className={styles.header}>
              <h2 style={{ marginRight: "1rem" }} className="page-header">
                Rewards
              </h2>
              <div className={styles.headerRight}>
                {/* <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={filteredUsers.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                <Button
                  style={{
                    width: "15.385vw",
                    height: "2.564vw",
                    padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                    gap: "0.641vw",
                    borderRadius: "4px",
                    color: "#ffffff",
                    fontSize: "0.897vw",
                    backgroundColor: "#37446F",
                    boxShadow:
                      " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                  }}
                  onClick={() => {
                    onAddClick();
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
            <table className={styles.mainTable}>
              <thead className={styles.tableHead}>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "2vw",
                      width: "20%",
                    }}
                  >
                    S. No.
                  </th>
                  <th style={{ width: "50%", textAlign: "left" }}>
                    brand Name
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "5.128vw",
                      width: "fit-content",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {brandsData?.map((brand) => (
                  <tr key={brand?.id} className={styles.tableBody}>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "3vw",
                        width: "20%",
                      }}
                    >
                      {brand?.id}
                    </td>
                    <td style={{ textAlign: "left" }}>{brand?.brand_name}</td>
                    <td style={{ paddingLeft: "5.128vw" }}>
                      <div className={styles.buttonContainer}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            onEditClick(brand?.id);
                          }}
                          style={{
                            backgroundColor: "#D1F4DE",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                            fontSize: "0.875rem",
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            onVoucherClick(brand?.id);
                          }}
                          style={{
                            backgroundColor: "#CBEBF6",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                            fontSize: "0.875rem",
                          }}
                        >
                          Vouchers
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            onBrandDelete(brand?.id);
                          }}
                          style={{
                            backgroundColor: "#FFD6D2",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                            fontSize: "0.875rem",
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Card>
        <Dialog open={isOpen.add || isOpen.update} onClose={handleDialogClose}>
          <DialogTitle style={{ fontWeight: "600" }}>
            {isOpen.add ? "Add New brand" : "Update brand"}
          </DialogTitle>
          <DialogContent>
            <TextField
              name="name"
              type="text"
              fullWidth
              className="mt-3"
              label="Brand Name"
              value={brandName}
              onChange={(e) => {
                setBrandName(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
            />
            <TextField
              name="order"
              type="text"
              fullWidth
              className="mt-3"
              label="Brand Order"
              value={brandOrder}
              onChange={(e) => {
                setBrandOrder(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
            />
            <TextField
              name="order"
              type="text"
              fullWidth
              className="mt-3"
              label="Image Url"
              value={imageUrl}
              onChange={(e) => {
                setImageUrl(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
            />
          </DialogContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1.282vw",
              padding: "24px",
              paddingTop: "0",
            }}
          >
            <Button
              className="outlined-button"
              style={{ flex: "1", width: "auto !important" }}
              onClick={() => {
                handleDialogClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="filled-button"
              style={{ flex: "1", width: "auto !important" }}
              onClick={() => {
                isOpen.add ? onBrandAdd() : onBrandUpdate();
              }}
            >
              {isOpen.add ? "Add" : "Update"}
            </Button>
          </div>
        </Dialog>
        <Dialog
          open={deleteOpen}
          onClose={(prev) => !prev}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ textAlign: "center" }}
        >
          <ErrorOutlineIcon
            style={{ color: "red", fontSize: "32px", margin: "12px auto" }}
          />
          <DialogTitle
            id="alert-dialog-title"
            style={{ fontSize: "18px", fontWeight: "500", lineHeight: "0" }}
          >
            {"Are you Sure?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: "12px" }}
            >
              You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDeleteOpen(false)}
              style={{
                textTransform: "none",
                width: "120px",
                height: "36px",
                border: "2.03px solid #37446F",
                borderRadius: "5.85px",
                background: "none",
                color: "black",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              style={{
                textTransform: "none",
                width: "120px",
                height: "36px",
                borderRadius: "5.85px",
                background: "#37446F",
                color: "white",
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Brands;
