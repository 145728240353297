import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const DeleteDialog = ({ open, onClose, onDelete, category }) => {
  /* Delete Dialog Box */
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: 'center' }}
      >
        <ErrorOutlineIcon style={{ color: 'red', fontSize: '32px', margin: '12px auto' }} />
        <DialogTitle id="alert-dialog-title" style={{ fontSize: '18px', fontWeight: '500', lineHeight: '0' }}>
          {"Are you Sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: '12px' }}>
            You won't be able to revert this!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} style={{ textTransform: 'none', width: '120px', height: '36px', border: '2.03px solid #37446F', borderRadius: '5.85px', background: 'none', color: 'black' }}>Cancel</Button>
          <Button onClick={() => onDelete(category)} style={{ textTransform: 'none', width: '120px', height: '36px', borderRadius: '5.85px', background: '#37446F', color: 'white' }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteDialog