import Highlight from '@tiptap/extension-highlight'
import TextAlign from '@tiptap/extension-text-align'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import Document from '@tiptap/extension-document'
import FontFamily from '@tiptap/extension-font-family'
import Paragraph from '@tiptap/extension-paragraph'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import styles from "./TextEditor.module.css"
import React, { useEffect, useState } from 'react'
import Toolbar from './Toolbar'
import Placeholder from '@tiptap/extension-placeholder'
import "./placeholder.css"
import Link from '@tiptap/extension-link'

const extensions = [StarterKit]

const TextEditor = ({customStyle=null, propsContent,onChangeContent,propPlaceholder,index=-1}) => {
  // console.log(propsContent,index);
  // const [pContent,setPContent]=useState(propsContent)
  
    const handleChange=(newContent)=>{
      console.log(newContent,index);
      
      if (index===-1) {
        onChangeContent(newContent)
        
      }else{
        onChangeContent(newContent,index)
      }
    }
    // console.log(propPlaceholder);



    // useEffect(()=>{
    //   if (index===-1) {
    //     console.log("Deleted");
        
    //   }
    // },[index])
    
    const editor = useEditor({
        editorProps:{
            attributes:{
                class:styles.EditorMain
            }
        },
        extensions: [
            StarterKit,
            Placeholder.configure({
              placeholder: propPlaceholder,
            }),
            TextAlign.configure({
              types: ['heading', 'paragraph'],
            }),
            Highlight,
            FontFamily,
            Underline,
            TextStyle,
            Link.configure({
              openOnClick: false,
              autolink: true,
              defaultProtocol: 'https',
            })
          ],

        
      
        content:"",
        onUpdate:({editor})=>{
            handleChange(editor.getHTML())
        },
        onDestroy:()=>{
          console.log("Getting Destroyed");
          
        }

      })



      useEffect(()=>{
        console.log(propsContent);
        editor.commands.setContent(propsContent, false,{preserveWhitespace:"full"})
        console.log(editor.getHTML());
        
        handleChange(editor.getHTML())
      },[propsContent])
 
    return (
    <div className={styles.editorContainer}>
        <Toolbar editor={editor} content={propsContent} />
        <EditorContent style={{height:(customStyle === "war" || customStyle === "battle") ? "7.692vw" : "85%",whiteSpace: "pre-line", overflowY: (customStyle === "war" || customStyle === "battle") && 'scroll'}} editor={editor} spellCheck={false} />
    </div>
  )
  
}

export default TextEditor