import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import StageList from "./StageList";
import AddUpdateDialog from "./AddUpdateDialog";
import service from "./stageService";
import { useNavigate, useParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import War from "../war/War";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ArrowBackIosNewRounded } from "@mui/icons-material";

const Stages = () => {
  const { skillId } = useParams();
  const [stages, setStages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newStage, setNewStage] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [replicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [newSkillId, setNewSkillId] = useState("");
  const [newStageName, setNewStageName] = useState("");
  const [replicateStageId, setReplicateStageId] = useState(null);
  const [skills, setSkills] = useState([]);
  const [selectedSkillName, setSelectedSkillName] = useState("");
  const [deleteOpen,setDeleteOpen]=useState(false)
  const [stageToDelete,setStageToDelete]=useState(null)
  const [token,setToken] = useLocalStorage('token',null);

  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(4);

  useEffect(() => {
    fetchStages();
    fetchSkills();
  }, []);

  const fetchStages = async () => {
    try {
      
      const fetchedStages = await service.getStages(token, skillId);
      setStages(fetchedStages);
    } catch (error) {
      console.error("Error fetching stages: ", error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const skillsData = response.data.data || [];
      // console.log("Fetched skills data:", skillsData);

      setSkills(skillsData);

      const selectedSkill = skillsData.find(skill => skill.skill_id === parseInt(skillId, 10));
      setSelectedSkillName(selectedSkill ? selectedSkill.skill_name : "Unknown Skill");
    } catch (error) {
      console.error("Error fetching skills:", error);
      setSkills([]);
      setSelectedSkillName("Unknown Skill");
    }
  };

  useEffect(() => {
    fetchStages();
    fetchSkills();
  }, [skillId]);

  const handleUpdateStage = async () => {
    try {

      const success = await service.updateStage(token, newStage.stage_id, {
        name: newStage.stage_name,
        specializationId: newStage.specialization_id,
        skillId: skillId,
        description: newStage.description,
        order: newStage.stage_order,
      });
      console.log(success)

      if (success.status===200) {
        toast.success(`${success.data.message}`);
        fetchStages();
        handleCloseUpdateDialog();
      } else if(success.status===400){
        toast.error(`${success.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleOpenUpdateDialog = (stage) => {
    console.log(stage);
    setFormFields(Object.keys(stage));
    setNewStage(stage);
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    setNewStage({});
  };

  const handleMission = (stageId) => {
    console.log(`Mission for Stage ${stageId}`);
  };

  const handleConfirmDelete=async()=>{
    try {
      const success = await service.deleteStage(token, stageToDelete);

      if (success) {
        toast.success("Stage deleted successfully");
        fetchStages();
      }
    } catch (error) {
      console.error("Error deleting stage: ", error);
      toast.error(`Error deleting stage: ${error.message}`);
    }

    setDeleteOpen(false)
    setStageToDelete(null)
  }

  const handleDelete = async (stageId) => {
    setDeleteOpen(true)
    setStageToDelete(stageId)
  };

  const handleReplicate = (stageId) => {
    setReplicateDialogOpen(true);
    setReplicateStageId(stageId);
  };

  const handleReplicateDialogClose = () => {
    setReplicateDialogOpen(false);
    setNewSkillId("");
    setNewStageName("");
  };

  const handleReplicateConfirm = async () => {
    try {

      const success = await service.replicateStage(
        token,
        replicateStageId,
        newSkillId,
        newStageName,
        skillId
      );
  
      if (success) {
        toast.success("Stage replicated successfully");
        fetchStages();
        handleReplicateDialogClose();
      } else {
        toast.error("Failed to replicate stage.");
      }
    } catch (error) {
      console.error("Error replicating stage: ", error);
      toast.error(`Error replicating stage: ${error.response.data.message}`);
    }
  };
  const handleAdd = () => {
    setOpenDialog(true);
    setNewStage({});
  };

  const handleAddStage = async () => {
    try {

      const response = await service.addStage(token, skillId, {
        name: newStage.name,
        specializationId: newStage.specializationId,
        skillId: skillId,
        description: newStage.description,
        order: newStage.order,
      });
  
      if (response && response.status === 201) {
        toast.success(`${response.data.message}`);
        fetchStages();
        handleCloseDialog();
      } else if (response && response.status === 400) {
        toast.error(`${response.data.message}`);
      } else {
        toast.error('Failed to add stage. Please try again.');
      }
    } catch (error) {
      console.error("Error adding stage: ", error);
      toast.error(`${error.response.data.message}`);
    }
  };
  

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewStage({});
  };

  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/skills')
  }

  return (
    <div className="container-fluid" style={{marginTop: '6vw'}}>
      <div className="align-center-all full-height">
        {isAuthorized ? (
          <Card style={{boxShadow: 'none'}} className="col-md-12 p-3">
            <CardContent>
              <div className="top-div">
                <div style={{display: 'flex', gap: '1vw', alignItems: 'baseline'}}>
                <ArrowBackIosNewRounded onClick={handleBack} style={{cursor: 'pointer'}}/> <h2>Stages of {selectedSkillName}</h2>
                </div>
              <ToastContainer />
                <Button
                  variant="contained"
                  className="mr-2 filled-button"
                  onClick={handleAdd}
                  style={{ float: "right" }}
                >
                  Add
                </Button>
              </div>
              <StageList
                stages={stages}
                handleOpenUpdateDialog={handleOpenUpdateDialog}
                handleMission={handleMission}
                handleDelete={handleDelete}
                handleReplicate={handleReplicate}
              />
              <AddUpdateDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                formFields={[
                  "name",
                  "order",
                  "description",
                  "specializationId",
                ]}
                newStage={newStage}
                setNewStage={setNewStage}
                handleSubmit={handleAddStage}
              />
              <AddUpdateDialog
                open={openUpdateDialog}
                handleClose={handleCloseUpdateDialog}
                formFields={formFields}
                newStage={newStage}
                setNewStage={setNewStage}
                handleSubmit={handleUpdateStage}
              />
              <Dialog
                open={replicateDialogOpen}
                onClose={handleReplicateDialogClose}
                PaperProps={{
                  style: {
                    width: "35.128vw",
                    maxWidth: '100vw',
                    borderRadius: "0.513vw",
                    boxShadow:
                      "4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80",
                  },
                }}
              >
                <DialogTitle>Replicate Stage</DialogTitle>
                <DialogContent>
                <FormControl
                fullWidth
                margin="normal"
                className="customTextfield"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
                >
                  <InputLabel id="skill-select-label">Skill</InputLabel>
                  <Select
                    labelId="skill-select-label"
                    value={newSkillId}
                    onChange={(e) => setNewSkillId(e.target.value)}
                    label="Skill"
                  >
                    {skills.map((skill) => (
                      <MenuItem key={skill.skill_id} value={skill.skill_id}>
                        {skill.skill_name}
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                  <TextField
                    label="New Stage Name"
                    value={newStageName}
                    onChange={(e) => setNewStageName(e.target.value)}
                    fullWidth
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      mb: '1rem'
                    }}
                  />
                <div className="dialog-actions-box">
                  <Button onClick={handleReplicateDialogClose} className="outlined-button" variant="outlined">
                    Cancel
                  </Button>
                  <Button onClick={handleReplicateConfirm} className="filled-button" variant="contained">
                    Replicate
                  </Button>
                </div>
                </DialogContent>
              </Dialog>
              <Dialog
          open={deleteOpen}
          onClose={(prev) => !prev}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ textAlign: "center" }}
        >
          <ErrorOutlineIcon
            style={{ color: "red", fontSize: "32px", margin: "12px auto" }}
          />
          <DialogTitle
            id="alert-dialog-title"
            style={{ fontSize: "18px", fontWeight: "500", lineHeight: "0" }}
          >
            {"Are you Sure?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: "12px" }}
            >
              You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {setDeleteOpen(false);
                setStageToDelete(null)}
              }
              style={{
                textTransform: "none",
                width: "120px",
                height: "36px",
                border: "2.03px solid #37446F",
                borderRadius: "5.85px",
                background: "none",
                color: "black",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              style={{
                textTransform: "none",
                width: "120px",
                height: "36px",
                borderRadius: "5.85px",
                background: "#37446F",
                color: "white",
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
            </CardContent>
          </Card>
        ) : (
          <h2>You are unauthorized to access this page.</h2>
        )}
      </div>
      <War stages={stages?.stages} />
    </div>
  );
};

export default Stages;
