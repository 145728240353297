import {
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Table,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material";
import { ArrowBackIosNewRounded } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from "@mui/material/TableCell";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Link, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styles from "./BlogCategories.module.css";
import axios from "axios";
import { ReactComponent as Hemberger } from "../assets/icons/Hemberger.svg";
import { ReactComponent as UserIcon } from "../assets/icons/UserIcon.svg";
import Sidebar from "../sidenav/Sidenav";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BlogCategoriesMobile = () => {
  /* on Successfull Update/Add Dialog */
  const [isSuccessfullAddCalled, setIsSuccessfullAddCalled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setIsSuccessfullAddCalled(false);
  };
  /* Delete Dialog Box */
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCatId, setSelectedCatId] = useState("")
  const [categoryDetails, setCategoryDetails] = useState({
    name: "",
    order: "",
  });
  const [isOpen, setIsOpen] = useState({
    add: false,
    update: false,
  });
  const navigate = useNavigate();
  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/categories/all`
      );

      if ((response.status = 200)) {
        console.log(response.data.data);
        setCategoriesData(response.data.data);
      }
    } catch (error) {
      console.log(error.response.data.error);

      toast.error(error.response.data.message);
    }
  };

  const onArticleClick = (id) => {
    console.log(id, " onArticleClick");
    navigate(`details/${id}`);
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const handleInputChange = (e) => {
    const name = e.target.name;
    setCategoryDetails((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleDialogClose = () => {
    setSelectedCatId("")
    setIsOpen({
      add: false,
      update: false,
    });
    setCategoryDetails({
      name: "",
      order: "",
    });
  };

  const onAddClick = () => {
    setIsOpen(prev => ({
      ...prev,
      add: true
    }))
  }


  const onEditClick = async (id) => {
    setIsOpen(prev => ({
      ...prev,
      update: true
    }))
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${id}`)
      console.log(response.data.data);

      setCategoryDetails({
        name: response.data.data.category_name,
        order: response.data.data.category_order
      })
      setSelectedCatId(response.data.data.category_id)
      setIsOpen(prev => ({
        ...prev,
        update: true
      }))
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  const onCategoryAdd = async () => {
    // setOpen(true); // just for demo
    if (categoryDetails.name === "" || categoryDetails.order === "") {
      toast.error("Please fill all the fields")
      return
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category`, {
        name: categoryDetails.name,
        order: categoryDetails.order
      })

      if (response.status = 200) {
        setIsOpen(prev => ({
          ...prev,
          add: false
        }));
        // toast.success("Category Added Sucessfully"); // removed due to update dialog box 
        setOpen(true);
        setIsSuccessfullAddCalled(true);
        fetchAllCategories()
        handleDialogClose()
      }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error(error.response.data.error);

    }
  }

  const onCategoryUpdate = async () => {
    if (categoryDetails.name === "" || categoryDetails.order === "") {
      toast.error("Please fill all the fields")
      return
    }
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${selectedCatId}`, {
        name: categoryDetails.name,
        order: categoryDetails.order
      })

      if (response.status = 200) {
        setIsOpen(prev => ({
          ...prev,
          update: false
        }));
        // toast.success("Category Added Sucessfully");
        setOpen(true);
        fetchAllCategories()
        handleDialogClose()
      }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error(error.response.data.error);

    }
  }
  const [categorySelectedForDelete, setCategorySelectedForDelete] = useState(null);
  const onCategoryDelete = async (id) => {
    console.log(id, " onCategoryDelete");
    // setOpenDeleteDialog(false); // remove this just for demo
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${id}`)

      if (response.status === 200) {
        // toast.success("Category Deleted Sucessfully")
        setOpenDeleteDialog(false);
        fetchAllCategories()
      }

    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);

    }
  }

  /* MUI Table */
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell component="th" scope="row">
            <div>{row?.category_name}</div>
          </TableCell>
          <TableCell style={{ textAlign: 'end' }}>
            <IconButton
              aria-label="expand row"
              size="small"
              style={{color: 'black'}}
              onClick={() => setOpen(!open)}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black', }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit  >
              <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                <div style={{ flex: '1' }}>Actions:</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                  <Button
                    className="actionMobileBtn"
                    onClick={() => { onEditClick(row?.category_id) }}
                    style={{
                      backgroundColor: "#D1F4DE",
                      color: "black",
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    className="actionMobileBtn"
                    onClick={() => {
                      onArticleClick(row?.category_id);
                    }}
                    style={{
                      backgroundColor: "#CBEBF6",
                      color: "black",
                    }}
                  >
                    Article
                  </Button>
                  <Button
                    className="actionMobileBtn"
                    onClick={() => { setCategorySelectedForDelete(row?.category_id); handleDeleteDialog() }}
                    style={{
                      backgroundColor: "#FFD6D2",
                      color: "black",
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const handleBack = () => {
    navigate('/dashboard');
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to={'/profile'}>
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />

      <div className="container-fluid">
        <div className={styles.header}>
          <h4 style={{display: 'flex', alignItems: 'center', gap: '0.2rem'}}>
            <ArrowBackIosNewRounded onClick={handleBack} style={{cursor: 'pointer'}}/>Blog Categories
          </h4>
          <div className={styles.headerRight}>
            <Button
              className="button-two"
              style={{
                width: '6rem', height: '1.75rem', background: '#37446F'
              }}
              onClick={() => {
                onAddClick()
              }}
            >
              Add
            </Button>
          </div>
        </div>

        <Table aria-label="collapsible table" sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none"
          }
        }}>
          <TableHead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
            <TableRow>
              <TableCell>Category Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {categoriesData
              ?.map((category, ind) => (
                <Row key={ind} row={category} />
              ))}
          </TableBody>
        </Table>


        <Dialog open={isOpen.add || isOpen.update} onClose={handleDialogClose}>
          <DialogTitle style={{ fontWeight: '600' }}>
            {isOpen.add ? "Add New CateGory" : "Update Category"}
          </DialogTitle>
          <DialogContent>
            <TextField
              name="name"
              type="text"
              label="New Category Name"
              value={categoryDetails.name}
              onChange={(e) => {
                handleInputChange(e);
              }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
                marginBottom: '1rem',
                marginTop: '5px'
              }}
              InputProps={{
                style: {
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false
              }}
            />
            <TextField
              name="order"
              type="text"
              label="Category Order"
              value={categoryDetails.order}
              onChange={(e) => {
                handleInputChange(e);
              }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false
              }}
            />
          </DialogContent>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '0.4rem', fontSize: '0.75rem', padding: '0 1.5rem 1rem 1.5rem' }}>
            <Button
              className="formBtnMobile"
              style={{
                color: "#000000",
                border: "2.03px solid #313C63",
                backgroundColor: "#ffffff",
                boxShadow: "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                flex: '1',
              }}
              onClick={() => { handleDialogClose() }}
            >
              Cancel
            </Button>
            <Button
              className="formBtnMobile"
              style={{
                color: "#ffffff",
                backgroundColor: "#37446F",
                boxShadow: "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                flex: '1',
              }}
              onClick={() => { isOpen.add ? onCategoryAdd() : onCategoryUpdate() }}
            >
              {isOpen.add ? "Add" : "Update"}
            </Button>
          </div>
        </Dialog>
        
        {/* Open when onCategoryAdd is invoked */}
        <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <div style={{ marginBottom: '2rem' }}>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                  })}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div style={{ width: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: '3rem', paddingTop: '0' }}>
                <CheckCircleIcon style={{ color: 'green', fontSize: '2rem', margin: '1rem' }} />
                <h4 style={{textAlign: 'center'}}>{isSuccessfullAddCalled ? "Added" : "Updated"} Successful!</h4>
                <p style={{ textAlign: 'center', fontSize: '0.75rem' }}>Category has been {isSuccessfullAddCalled ? "added" : "updated"} successfully.</p>
              </div>
        </BootstrapDialog>

        {/* Delete Dialog Box */}
        <Dialog
          open={openDeleteDialog}
          onClose={(prev) => !prev}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ textAlign: 'center' }}
        >
          <ErrorOutlineIcon style={{ color: 'red', fontSize: '32px', margin: '12px auto' }} />
          <DialogTitle id="alert-dialog-title" style={{ fontSize: '18px', fontWeight: '500', lineHeight: '0' }}>
            {"Are you Sure?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ fontSize: '12px' }}>
              You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} style={{ textTransform: 'none', width: '120px', height: '36px', border: '2.03px solid #37446F', borderRadius: '5.85px', background: 'none', color: 'black' }}>Cancel</Button>
            <Button onClick={() => onCategoryDelete(categorySelectedForDelete)} style={{ textTransform: 'none', width: '120px', height: '36px', borderRadius: '5.85px', background: '#37446F', color: 'white' }} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default BlogCategoriesMobile;
