import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TableCell,
    TablePagination,
    TableRow,
    TextField,
    Select,
    MenuItem
} from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styles from "./LearnPage.module.css";
import axios from "axios";
import TextEditor from "../Components/TextEditor/TextEditor";
import DeleteDialog from "../Components/ConfirmationDialog/DeleteDialog";
import isDesktop from "../isDesktop";

const LearnPage = () => {
    const [isOpenSpecialization, setIsOpenSpecialization] = useState(false);
    const [isOpenSkill, setIsOpenSkill] = useState(false);
    const [isAdding, setIsAdding] = useState(true);
    const [learnData, setLearnData] = useState([]);
    const [subTitle, setSubTitle] = useState([]);
    const [selectedLearnId, setSelectedLearnId] = useState("");
    const [category, setCategory] = useState("specialization");

    console.log(learnData);
    /* Delete Dialog Box */
    const [selectedItemForDelete, setSelectedItemForDelete] = useState(null);
    const [openDelDialog, setOpenDelDialog] = useState(false);
    const handleDeleteDialog = () => {
        setOpenDelDialog(true);
    };
    const openAddDialog = () => {
        setIsAdding(true);
        setCategory("specialization");
        setLearnDetails({
            title: "",
            order: "",
            imageURL: "",
            detailedDescription: "",
            shortDescription: "",
            keyTakeaways: "",
        });
        setSubTitle([]);
        setIsOpenSpecialization(true);
    };
    
    const openAddSkillDialog = () => {
        setIsAdding(true);
        setCategory("skill");
        setLearnDetails({
            title: "",
            order: "",
            imageURL: "",
            detailedDescription: "",
            shortDescription: "",
            keyTakeaways: "",
        });
        setSubTitle([]);
        setIsOpenSkill(true);
    };

    const [learnDetails, setLearnDetails] = useState({
        title: "",
        learnOrder: "",
        imageURL: "",
        detailedDescription: "",
        shortDescription: "",
        keyTakeaways: "",
    });    

    const getAllLearnData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/learn-seo/learn/all`
            );
            if (response.status === 200) {
                setLearnData(response.data.data);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const addLearnItem = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/learn-seo/learn`,
                {
                    title: learnDetails.title,
                    learnOrder: learnDetails.learnOrder,
                    imageURL: learnDetails.imageURL,
                    detailedDescription: learnDetails.detailedDescription,
                    shortDescription: learnDetails.shortDescription,
                    keyTakeaways: learnDetails.keyTakeaways,
                    subtitleTitles: subTitle.map(sub => sub.title),
                    subtitleDescriptions: subTitle.map(sub => sub.description),
                    category: category,
                }                
            );
    
            if (response.status === 200) {
                toast.success("Learn item added successfully");
                getAllLearnData();
                handleCloseDialog();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    

    const editLearnItem = async (id, itemCategory) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/learn-seo/learn/${id}`);
    
            // Update learnDetails state with fetched data
            const data = response.data.data;
            setLearnDetails({
                title: data.title,
                learnOrder: data.learn_order,
                imageURL: data.image_url,
                detailedDescription: data.detailed_description || "",
                shortDescription: data.short_description || "",
                keyTakeaways: data.key_takeaways || "",
                category: itemCategory,
            });            
    
            setSelectedLearnId(data.learn_id);
            let temp=[]
            if (response.data.data.subtitle_titles) {
              const descriptionData=JSON.parse(response.data.data.subtitle_descriptions)
              temp=JSON.parse(response.data.data.subtitle_titles).map((val,ind)=>{
                return{
                  title:val,
                  description:descriptionData[ind]
                }
              })
            }
            console.log(response.data.data.subtitle_titles);
      
            setSubTitle(temp)
            setCategory(itemCategory);
            setIsAdding(false);
    
            // Open the dialog after state is updated
            if (itemCategory === "specialization") {
                setIsOpenSpecialization(true);
            } else if (itemCategory === "skill") {
                setIsOpenSkill(true);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    

    const updateLearnItem = async () => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/learn-seo/learn/${selectedLearnId}`,
                {
                    title: learnDetails.title,
                    learnOrder: learnDetails.learnOrder,
                    imageURL: learnDetails.imageURL,
                    detailedDescription: learnDetails.detailedDescription,
                    shortDescription: learnDetails.shortDescription,
                    keyTakeaways: learnDetails.keyTakeaways,
                    subtitleTitles: subTitle.map(sub => sub.title),
                    subtitleDescriptions: subTitle.map(sub => sub.description),
                    category: category,
                }                
            );
    
            if (response.status === 200) {
                toast.success("Learn item updated successfully");
                getAllLearnData();
                handleCloseDialog();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    

    const deleteLearnItem = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/learn-seo/learn/${id}`);
            if (response.status === 200) {
                setOpenDelDialog(false);
                toast.success("Learn item deleted successfully");
                getAllLearnData();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const handleCloseDialog = () => {
        setSelectedLearnId("");
        setIsOpenSpecialization(false);
        setIsOpenSkill(false);
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setLearnDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    useEffect(() => {
        getAllLearnData();
    }, []);

    const addSubtitle = () => {
        setSubTitle((prev) => [
            ...prev,
            { title: "", description: "" },
        ]);
    };

    const onSubtitleChange = (e, index) => {
        setSubTitle((prev) => {
            const temp = [...prev];
            temp[index].title = e.target.value;
            return temp;
        });
    };

    const onSubDescriptionChange = (value, index) => {
        setSubTitle((prev) => {
            const temp = [...prev];
            temp[index].description = value;
            return temp;
        });
    };

    const onDetailedDescriptionChange = (value) => {
        setLearnDetails((prev) => ({
            ...prev,
            detailedDescription: value,
        }));
    };

    const onShortDescriptionChange = (value) => {
        setLearnDetails((prev) => ({
            ...prev,
            shortDescription: value,
        }));
    };

    const onSummaryChange = (value) => {
        setLearnDetails((prev) => ({
            ...prev,
            keyTakeaways: value,
        }));
    };

    const onDeleteSubtitle = (index) => {
        setSubTitle((prev) => prev.filter((_, ind) => ind !== index));
    };

    const isDesktopDevice = isDesktop();

    return (
        <>
            {
                isDesktopDevice ? (
                    <>
                        <div>
                            <div style={{ paddingTop: '5vw' }}>
                                <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                                    <CardContent>
                                        <div className={styles.header}>
                                            <h2 style={{ marginRight: "1rem" }} className="page-header">
                                                Specialization
                                            </h2>
                                            <div className={styles.headerRight}>
                                                <Button
                                                    style={{
                                                        width: "15.385vw",
                                                        height: "2.564vw",
                                                        padding: "1.026vw 2.308vw",
                                                        gap: "0.641vw",
                                                        borderRadius: "4px",
                                                        color: "#ffffff",
                                                        fontSize: "0.897vw",
                                                        backgroundColor: "#37446F",
                                                        boxShadow: "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                    }}
                                                    onClick={openAddDialog}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                        <table className={styles.mainTable}>
                                            <thead className={styles.tableHead}>
                                                <tr>
                                                    <th style={{ textAlign: "left", paddingLeft: "2vw", width: "20%" }}>S. No.</th>
                                                    <th style={{ width: "50%", textAlign: "left" }}>Name</th>
                                                    <th style={{ textAlign: "left", paddingLeft: "5.128vw", width: "fit-content" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {learnData
                                                    .filter((learn) => learn.category === "specialization") // Filter for specialization
                                                    .map((learn, index) => (
                                                    <tr key={learn.learn_id} className={styles.tableBody}>
                                                        <td style={{ textAlign: "left", paddingLeft: "3vw", width: "20%" }}>{index + 1}</td>
                                                        <td style={{ textAlign: "left" }}>{learn.title}</td>
                                                        <td style={{ paddingLeft: "5.128vw" }}>
                                                            <div className={styles.buttonContainer}>
                                                                <Button
                                                                    variant="contained"
                                                                    fullWidth
                                                                    onClick={() => editLearnItem(learn.learn_id, "specialization")}
                                                                    style={{
                                                                        backgroundColor: "#D1F4DE",
                                                                        height: "2.051vw",
                                                                        width: "7.179vw",
                                                                        color: "black",
                                                                        textTransform: "capitalize",
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    fullWidth
                                                                    onClick={() => {setSelectedItemForDelete(learn.learn_id); handleDeleteDialog();}}
                                                                    style={{
                                                                        backgroundColor: "#FFD6D2",
                                                                        height: "2.051vw",
                                                                        width: "7.179vw",
                                                                        color: "black",
                                                                        textTransform: "capitalize",
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <DeleteDialog 
                                            open={openDelDialog} 
                                            onClose={() => setOpenDelDialog(false)} 
                                            onDelete={() => deleteLearnItem(selectedItemForDelete)} 
                                            category={selectedItemForDelete}
                                        />
                                    </CardContent>
                                </Card>
                            </div>
                            <Dialog
                                open={isOpenSpecialization}
                                onClose={() => setIsOpenSpecialization(false)}
                                PaperProps={{
                                    style: {
                                        width: "auto",
                                        maxWidth: "100vw",
                                        height: "35.128vw",
                                        padding: "1.5rem",
                                        borderRadius: "0.513vw",
                                        boxShadow: "4px 4px 8px 0px #AEAEC099, -4px -4px 8px 0px #FFFFFF80",
                                    },
                                }}
                            >
                                <DialogTitle
                                    style={{
                                        paddingLeft: "0",
                                        fontWeight: "600",
                                        paddingTop: "0",
                                    }}
                                >
                                    {isAdding ? "Add New Specialization" : "Update Specialization"}
                                </DialogTitle>
                                <DialogContent style={{ paddingTop: "1vw" }}>
                                    <div className={styles.dialogContentContainer}>
                                        <TextField
                                            label="Category"
                                            value="specialization"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            style={{ marginBottom: '1rem' }}
                                        />
                                        <TextField
                                            type="text"
                                            label="Specialization Name"
                                            value={learnDetails.title}
                                            name="title"
                                            onChange={onInputChange}
                                        />
                                        <TextField
                                            type="text"
                                            name="learnOrder"
                                            label="Specialization Order"
                                            onChange={onInputChange}
                                            value={learnDetails.learnOrder}
                                        />
                                        <TextField
                                            type="text"
                                            name="imageURL"
                                            label="Image URL"
                                            onChange={onInputChange}
                                            value={learnDetails.imageURL}
                                        />
                                        <div className={styles.shortDescriptionContainer}>
                                            <TextEditor
                                                propsContent={learnDetails.shortDescription}
                                                propPlaceholder="Short Description..."
                                                onChangeContent={onShortDescriptionChange}
                                            />
                                        </div>
                                        {/* <div className={styles.descriptionContainer}>
                                            <TextEditor
                                                propsContent={learnDetails.detailedDescription}
                                                propPlaceholder="Detailed Description..."
                                                onChangeContent={onDetailedDescriptionChange}
                                            />
                                        </div>
                                        <div className={styles.summaryContainer}>
                                            <TextEditor
                                                propsContent={learnDetails.keyTakeaways}
                                                propPlaceholder="key takeaways"
                                                onChangeContent={onSummaryChange}
                                            />
                                        </div> */}
                                        {subTitle.map((sub, index) => (
                                            <div key={index} className={styles.subtitleContainer}>
                                                <TextField
                                                    type="text"
                                                    name="title"
                                                    label="Subtitle Title"
                                                    value={sub.title}
                                                    onChange={(e) => onSubtitleChange(e, index)}
                                                />
                                                <div className={styles.subDescriptionContainer}>
                                                    <TextEditor
                                                        key={index}
                                                        index={index}
                                                        propsContent={sub.description}
                                                        propPlaceholder="Subtitle Description..."
                                                        onChangeContent={onSubDescriptionChange}
                                                    />
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={() => onDeleteSubtitle(index)}
                                                    style={{
                                                        backgroundColor: "#FFD6D2",
                                                        height: "2.051vw",
                                                        width: "7.179vw",
                                                        color: "black",
                                                        marginTop: "1vw",
                                                        textTransform: "capitalize",
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        ))}
                                        <Button
                                            style={{
                                                width: "15.385vw",
                                                height: "2.564vw",
                                                padding: "1.026vw 2.308vw",
                                                gap: "0.641vw",
                                                borderRadius: "4px",
                                                color: "#ffffff",
                                                fontSize: "0.897vw",
                                                backgroundColor: "#37446F",
                                                gridColumn: "1/2",
                                                boxShadow:
                                                    "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                            }}
                                            onClick={addSubtitle}
                                        >
                                            Add Subtitle
                                        </Button>
                                    </div>
                                    <div className={styles.lowerContainer}>
                                        <Button
                                            style={{
                                                width: "15.385vw",
                                                height: "2.564vw",
                                                padding: "1.026vw 2.308vw",
                                                gap: "0.641vw",
                                                borderRadius: "4px",
                                                color: "#000000",
                                                border: "2.03px solid #313C63",
                                                fontSize: "0.897vw",
                                                backgroundColor: "#ffffff",
                                                boxShadow:
                                                    "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                            }}
                                            onClick={handleCloseDialog}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            style={{
                                                width: "15.385vw",
                                                height: "2.564vw",
                                                padding: "1.026vw 2.308vw",
                                                gap: "0.641vw",
                                                borderRadius: "4px",
                                                color: "#ffffff",
                                                fontSize: "0.897vw",
                                                backgroundColor: "#37446F",
                                                boxShadow:
                                                    "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                            }}
                                            onClick={() => {
                                                isAdding ? addLearnItem() : updateLearnItem();
                                            }}
                                        >
                                            {isAdding ? "Add" : "Update"}
                                        </Button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                        <div>
                            <div style={{ paddingTop: '5vw' }}>
                                <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                                    <CardContent>
                                        <div className={styles.header}>
                                            <h2 style={{ marginRight: "1rem" }} className="page-header">
                                                Skill
                                            </h2>
                                            <div className={styles.headerRight}>
                                                <Button
                                                    style={{
                                                        width: "15.385vw",
                                                        height: "2.564vw",
                                                        padding: "1.026vw 2.308vw",
                                                        gap: "0.641vw",
                                                        borderRadius: "4px",
                                                        color: "#ffffff",
                                                        fontSize: "0.897vw",
                                                        backgroundColor: "#37446F",
                                                        boxShadow: "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                    }}
                                                    onClick={openAddSkillDialog}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                        <table className={styles.mainTable}>
                                            <thead className={styles.tableHead}>
                                                <tr>
                                                    <th style={{ textAlign: "left", paddingLeft: "2vw", width: "20%" }}>S. No.</th>
                                                    <th style={{ width: "50%", textAlign: "left" }}>Name</th>
                                                    <th style={{ textAlign: "left", paddingLeft: "5.128vw", width: "fit-content" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {learnData
                                                    .filter((learn) => learn.category === "skill") // Filter for specialization
                                                    .map((learn, index) => (
                                                    <tr key={learn.learn_id} className={styles.tableBody}>
                                                        <td style={{ textAlign: "left", paddingLeft: "3vw", width: "20%" }}>{index + 1}</td>
                                                        <td style={{ textAlign: "left" }}>{learn.title}</td>
                                                        <td style={{ paddingLeft: "5.128vw" }}>
                                                            <div className={styles.buttonContainer}>
                                                                <Button
                                                                    variant="contained"
                                                                    fullWidth
                                                                    onClick={() => editLearnItem(learn.learn_id, "skill")}
                                                                    style={{
                                                                        backgroundColor: "#D1F4DE",
                                                                        height: "2.051vw",
                                                                        width: "7.179vw",
                                                                        color: "black",
                                                                        textTransform: "capitalize",
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    fullWidth
                                                                    onClick={() => {setSelectedItemForDelete(learn.learn_id); handleDeleteDialog();}}
                                                                    style={{
                                                                        backgroundColor: "#FFD6D2",
                                                                        height: "2.051vw",
                                                                        width: "7.179vw",
                                                                        color: "black",
                                                                        textTransform: "capitalize",
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>
                                <Dialog
                                    open={isOpenSkill}
                                    onClose={() => setIsOpenSkill(false)}
                                    PaperProps={{
                                        style: {
                                            width: "auto",
                                            maxWidth: "100vw",
                                            height: "35.128vw",
                                            padding: "1.5rem",
                                            borderRadius: "0.513vw",
                                            boxShadow:
                                                "4px 4px 8px 0px #AEAEC099, -4px -4px 8px 0px #FFFFFF80",
                                        },
                                    }}
                                >
                                    <DialogTitle
                                        style={{
                                            paddingLeft: "0",
                                            fontWeight: "600",
                                            paddingTop: "0",
                                        }}
                                    >
                                        {isAdding ? "Add New Skill" : "Update Skill"}
                                    </DialogTitle>
                                    <DialogContent style={{ paddingTop: "1vw" }}>
                                        <div className={styles.dialogContentContainer}>
                                            <TextField
                                                label="Category"
                                                value="skill"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                                style={{ marginBottom: '1rem' }}
                                            />
                                            <TextField
                                                type="text"
                                                label="Skill Name"
                                                value={learnDetails.title}
                                                name="title"
                                                onChange={onInputChange}
                                            />
                                            <TextField
                                                type="text"
                                                name="learnOrder"
                                                label="Skill Order"
                                                onChange={onInputChange}
                                                value={learnDetails.learnOrder}
                                            />
                                            <TextField
                                                type="text"
                                                name="imageURL"
                                                label="Image URL"
                                                onChange={onInputChange}
                                                value={learnDetails.imageURL}
                                            />
                                            <div className={styles.shortDescriptionContainer}>
                                                <TextEditor
                                                    propsContent={learnDetails.shortDescription}
                                                    propPlaceholder="Short Description..."
                                                    onChangeContent={onShortDescriptionChange}
                                                />
                                            </div>
                                            {/* <div className={styles.descriptionContainer}>
                                                <TextEditor
                                                    propsContent={learnDetails.detailedDescription}
                                                    propPlaceholder="Detailed Description..."
                                                    onChangeContent={onDetailedDescriptionChange}
                                                />
                                            </div>
                                            <div className={styles.summaryContainer}>
                                                <TextEditor
                                                    propsContent={learnDetails.keyTakeaways}
                                                    propPlaceholder="key takeaways"
                                                    onChangeContent={onSummaryChange}
                                                />
                                            </div> */}
                                            {subTitle.map((sub, index) => (
                                                <div key={index} className={styles.subtitleContainer}>
                                                    <TextField
                                                        type="text"
                                                        name="title"
                                                        label="Subtitle Title"
                                                        value={sub.title}
                                                        onChange={(e) => onSubtitleChange(e, index)}
                                                    />
                                                    <div className={styles.subDescriptionContainer}>
                                                        <TextEditor
                                                            key={index}
                                                            index={index}
                                                            propsContent={sub.description}
                                                            propPlaceholder="Subtitle Description..."
                                                            onChangeContent={onSubDescriptionChange}
                                                        />
                                                    </div>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={() => onDeleteSubtitle(index)}
                                                        style={{
                                                            backgroundColor: "#FFD6D2",
                                                            height: "2.051vw",
                                                            width: "7.179vw",
                                                            color: "black",
                                                            marginTop: "1vw",
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            ))}
                                            <Button
                                                style={{
                                                    width: "15.385vw",
                                                    height: "2.564vw",
                                                    padding: "1.026vw 2.308vw",
                                                    gap: "0.641vw",
                                                    borderRadius: "4px",
                                                    color: "#ffffff",
                                                    fontSize: "0.897vw",
                                                    backgroundColor: "#37446F",
                                                    gridColumn: "1/2",
                                                    boxShadow:
                                                        "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                }}
                                                onClick={addSubtitle}
                                            >
                                                Add Subtitle
                                            </Button>
                                        </div>
                                        <div className={styles.lowerContainer}>
                                            <Button
                                                style={{
                                                    width: "15.385vw",
                                                    height: "2.564vw",
                                                    padding: "1.026vw 2.308vw",
                                                    gap: "0.641vw",
                                                    borderRadius: "4px",
                                                    color: "#000000",
                                                    border: "2.03px solid #313C63",
                                                    fontSize: "0.897vw",
                                                    backgroundColor: "#ffffff",
                                                    boxShadow:
                                                        "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                }}
                                                onClick={handleCloseDialog}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                style={{
                                                    width: "15.385vw",
                                                    height: "2.564vw",
                                                    padding: "1.026vw 2.308vw",
                                                    gap: "0.641vw",
                                                    borderRadius: "4px",
                                                    color: "#ffffff",
                                                    fontSize: "0.897vw",
                                                    backgroundColor: "#37446F",
                                                    boxShadow:
                                                        "3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF",
                                                }}
                                                onClick={() => {
                                                    isAdding ? addLearnItem() : updateLearnItem();
                                                }}
                                            >
                                                {isAdding ? "Add" : "Update"}
                                            </Button>
                                        </div>
                                    </DialogContent>
                                </Dialog>

                            </div>
                        </div>

                    </>

                ) : (
                    <div>
                        Hello
                    </div>
                )
            }
        </>
    );
};

export default LearnPage;
