import React, { useEffect, useState } from "react";
import styles from "./Voucher.module.css";
import isDesktop from "../isDesktop";
import { toast,ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import { ArrowBackIosNewRounded } from "@mui/icons-material";
import axios from "axios";
import TextEditor from "../Components/TextEditor/TextEditor";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


const Voucher = () => {
  const [vouchersData, setVoucherData] = useState([]);
  const { id } = useParams();
  const [voucherName, setVoucherName] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  const [voucherOrder, setVoucherOrder] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [coins, setCoins] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [selectedVoucherId, setSelectedVoucherId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isOpen, setIsOpen] = useState({
    add: false,
    update: false,
  });

  const isDesktopDevice = isDesktop();
  const navigate = useNavigate();

  const onAddClick = () => {
    setIsOpen((prev) => ({
      ...prev,
      add: true,
    }));
  };

  const fetchVouchersData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand/voucher/brand/${id}`
      );

      setVoucherData(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };

  console.log(vouchersData, "vouchers data");

  useEffect(() => {
    fetchVouchersData();
  }, []);

  const onVoucherClick = (id) => {
    return navigate(`voucher/${id}`);
  };

  const onEditClick = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand/voucher/${id}`
      );

      const data = response.data.data;
      setVoucherName(data.name);
      setVoucherOrder(data.voucher_order);
      setSelectedVoucherId(id);
      // setImageUrl(data.image_url);
      setVoucherCode(data.voucher_code);
      setCost(data.cost);
      setCoins(data.coins_required);
      setDescription(data.description);
      setExpiryDate(dayjs(data.expiry_date).format("YYYY-MM-DD"));
      setIsOpen((prev) => ({
        ...prev,
        update: true,
      }));
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };

  const onVoucherUpdate = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand/voucher/${selectedVoucherId}`,
        {
          voucherOrder: voucherOrder,
          expiryDate: expiryDate,
          voucherName: voucherName,
          // ImageURL: imageUrl,
          voucherCode: voucherCode,
          description: description,
          cost: cost,
          coinsRequired: coins,
        }
      );
      if (response.status == 200) {
        toast.success("Data updated Sucessfully");
        handleDialogClose();
        fetchVouchersData();
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };

  const onVoucherAdd = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand/voucher`,
        {
          voucherOrder: voucherOrder,
          expiryDate: expiryDate,
          voucherName: voucherName,
          // ImageURL: imageUrl,
          brandId: id,
          voucherCode: voucherCode,
          description: description,
          cost: cost,
          coinsRequired: coins,
        }
      );

      if (response.status == 200) {
        toast.success("Data added sucessfully");
        handleDialogClose();
        fetchVouchersData();
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };

  const onVoucherDelete = async (id) => {
    setSelectedVoucherId(id);
    setDeleteOpen(true);
  };

  const handleDialogClose = () => {
    setVoucherName("");
    setVoucherOrder("");
    // setImageUrl("");
    setCoins("");
    setCost("");
    setDescription("");
    setVoucherCode("");
    setExpiryDate("");
    setIsOpen({
      add: false,
      update: false,
    });
  };

  const handleDateChange = (value) => {
    console.log(dayjs(value).format("DD-MM-YYYY"), " handleDateChange"); // '16-12-2001'
    const formattedDate = dayjs(value).format("YYYY-MM-DD");
    setExpiryDate(formattedDate);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/rewards/brand/voucher/${selectedVoucherId}`
      );
      if (response.status == 200) {
        toast.success("Data updated Sucessfully");
        setDeleteOpen(false);
        fetchVouchersData();
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
    }
  };
  const handleBack = () => {
    navigate('/rewards');
  }
  return (
    <>
    <ToastContainer />
      <div style={{ paddingTop: "5.5vw" }}>
        <Card
          elevation={3}
          className="col-md-12 p-3"
          style={{ boxShadow: "none" }}
        >
          <CardContent>
            <div className={styles.header}>
              <h2 className={styles.pageHeader}>
              <ArrowBackIosNewRounded onClick={handleBack} style={{cursor: 'pointer'}}/> Vouchers
              </h2>
              <div className={styles.headerRight}>
                {/* <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={filteredUsers.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                <Button
                  style={{
                    width: "15.385vw",
                    height: "2.564vw",
                    padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                    gap: "0.641vw",
                    borderRadius: "4px",
                    color: "#ffffff",
                    fontSize: "0.897vw",
                    backgroundColor: "#37446F",
                    boxShadow:
                      " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                  }}
                  onClick={() => {
                    onAddClick();
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
            <table className={styles.mainTable}>
              <thead className={styles.tableHead}>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "2vw",
                      width: "20%",
                    }}
                  >
                    S. No.
                  </th>
                  <th style={{ width: "50%", textAlign: "left" }}>
                    voucher Name
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      //   paddingLeft: "5.128vw",
                      width: "fit-content",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "5.128vw",
                      width: "fit-content",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {vouchersData?.map((voucher) => (
                  <tr key={voucher?.id} className={styles.tableBody}>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "3vw",
                        width: "20%",
                      }}
                    >
                      {voucher?.id}
                    </td>
                    <td style={{ textAlign: "left" }}>{voucher?.name}</td>
                    <td style={{ textAlign: "center" }}>
                      {voucher.redeemed_by == null ||
                      voucher.redeemed_by == "null"
                        ? "Active"
                        : "Redeemed"}
                    </td>
                    <td style={{ paddingLeft: "5.128vw" }}>
                      <div className={styles.buttonContainer}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            onEditClick(voucher?.id);
                          }}
                          style={{
                            backgroundColor: "#D1F4DE",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                            fontSize: "0.875rem",
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            onVoucherDelete(voucher?.id);
                          }}
                          style={{
                            backgroundColor: "#FFD6D2",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                            fontSize: "0.875rem",
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Card>
        <Dialog open={isOpen.add || isOpen.update} onClose={handleDialogClose}>
          <DialogTitle style={{ fontWeight: "600" }}>
            {isOpen.add ? "Add New voucher" : "Update voucher"}
          </DialogTitle>
          <DialogContent>
            <TextField
              name="name"
              type="text"
              fullWidth
              className="mt-3"
              label="Voucher Name"
              value={voucherName}
              onChange={(e) => {
                setVoucherName(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
            />
            <TextField
              name="order"
              type="text"
              fullWidth
              className="mt-3"
              label="Voucher Order"
              value={voucherOrder}
              onChange={(e) => {
                setVoucherOrder(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
            />
            {/* <TextField
              name="imageUrl"
              type="text"
              fullWidth
              className="mt-3"
              label="Image Url"
              value={imageUrl}
              onChange={(e) => {
                setImageUrl(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
            /> */}
            <FormControl
              fullWidth
              className="mb-3 mt-3"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
                "&.Mui-focused": { color: "#A8A8A8" },
              }}
              style={{
                boxShadow:
                  "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
              }}
            >
              {" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="YYYY-MM-DD"
                  value={dayjs(expiryDate)}
                  label="Expiry Date"
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField {...params} error={false} />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
            <div
              style={{
                marginTop: "16.5px",
              }}
            >
              <TextEditor
                propsContent={description}
                propPlaceholder={"Short Description...."}
                onChangeContent={(value) => {
                  setDescription(value);
                }}
              />
            </div>
            <TextField
              name="voucherCode"
              type="text"
              fullWidth
              className="mt-3"
              label="Voucher Code"
              value={voucherCode}
              onChange={(e) => {
                setVoucherCode(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
            />
            <TextField
              name="cost"
              type="text"
              fullWidth
              className="mt-3"
              label="Cost"
              value={cost}
              onChange={(e) => {
                setCost(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
            />
            <TextField
              name="coins"
              type="text"
              fullWidth
              className="mt-3"
              label="Coins"
              value={coins}
              onChange={(e) => {
                setCoins(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
            />
          </DialogContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1.282vw",
              padding: "24px",
              paddingTop: "0",
            }}
          >
            <Button
              className="outlined-button"
              style={{ flex: "1", width: "auto !important" }}
              onClick={() => {
                handleDialogClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="filled-button"
              style={{ flex: "1", width: "auto !important" }}
              onClick={() => {
                isOpen.add ? onVoucherAdd() : onVoucherUpdate();
              }}
            >
              {isOpen.add ? "Add" : "Update"}
            </Button>
          </div>
        </Dialog>
        <Dialog
          open={deleteOpen}
          onClose={(prev) => !prev}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ textAlign: "center" }}
        >
          <ErrorOutlineIcon
            style={{ color: "red", fontSize: "32px", margin: "12px auto" }}
          />
          <DialogTitle
            id="alert-dialog-title"
            style={{ fontSize: "18px", fontWeight: "500", lineHeight: "0" }}
          >
            {"Are you Sure?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: "12px" }}
            >
              You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDeleteOpen(false)}
              style={{
                textTransform: "none",
                width: "120px",
                height: "36px",
                border: "2.03px solid #37446F",
                borderRadius: "5.85px",
                background: "none",
                color: "black",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              style={{
                textTransform: "none",
                width: "120px",
                height: "36px",
                borderRadius: "5.85px",
                background: "#37446F",
                color: "white",
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Voucher;
